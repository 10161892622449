import { useEffect, useState } from 'react';
import marketingApi from '../../data/api/gatewayApi';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';

export const PrimaryMarketConsentModal: React.FC = () => {
  const currentLocation = useLocation().pathname;

  const consentStorageKeyName = 'primary-consent';
  const consentStorageKeyValue = 'true';

  const homePath = '/';

  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState<boolean | undefined>(
    undefined,
  );

  const [rejectModalVisible, setIsRejectModalVisible] = useState<
    boolean | undefined
  >(undefined);

  const rejectHandler = () => {
    if (currentLocation === homePath) {
      setIsModalVisible(false);
      return setIsRejectModalVisible(true);
    } else {
      setIsModalVisible(false);
      navigate('/');
    }
  };

  const consentHandler = () => {
    localStorage.setItem(consentStorageKeyName, consentStorageKeyValue);
    setIsModalVisible(false);
  };

  const consentRejectModalHandler = () => {
    setIsRejectModalVisible(false);
    setIsModalVisible(true);
  };

  const ShowPrimaryMarketModal = () => {
    const consentFromStorage =
      localStorage.getItem(consentStorageKeyName) === consentStorageKeyValue;

    if (!consentFromStorage) {
      marketingApi('/reverse-solicitation-config').then(res =>
        setIsModalVisible(res.data.reverseSolicitation),
      );
    } else {
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    ShowPrimaryMarketModal();
  }, [currentLocation]);

  useEffect(() => {
    if (isModalVisible || rejectModalVisible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isModalVisible, rejectModalVisible]);

  if (rejectModalVisible) {
    return (
      <div
        className={clsx(
          rejectModalVisible
            ? 'mkt-absolute mkt-w-screen mkt-h-screen mkt-bg-[rgba(0,0,0,0.5)] mkt-z-[99999] mkt-overflow-hidden mkt-flex mkt-justify-center mkt-items-center mkt-top-[-88px] lg:mkt-top-[-51px]'
            : 'mkt-hidden',
        )}
      >
        <div className="mkt-w-[90%] sm:mkt-w-[512px] mkt-h-[150px] sm:mkt-gap-4 mkt-bg-white mkt-rounded-md mkt-shadow-md mkt-p-3  sm:mkt-p-4 mkt-flex mkt-flex-col mkt-gap-3">
          <div className="mkt-text-center mkt-flex mkt-flex-col mkt-gap-1  mkt-tracking-[0.45px]">
            <p className="mkt-text-sm mkt-text-grey">
              Due to regulations, you need to confirm interest to see our
              investment opportunities
            </p>
          </div>
          <div className="mkt-flex mkt-justify-center">
            <div className="sm:mkt-order-2 mkt-w-full mkt-flex-col mkt-flex sm:mkt-w-[240px] mkt-justify-center mkt-items-center">
              <button
                onClick={consentRejectModalHandler}
                className="mkt-px-[30px] mkt-py-[11px] mkt-flex mkt-justify-center mkt-items-center mkt-rounded-3xl mkt-bg-gold mkt-text-white mkt-w-full mkt-font-medium mkt-tracking-[0.4px] mkt-transition-all hover:mkt-bg-white hover:mkt-border hover:mkt-border-gold hover:mkt-text-gold"
              >
                Go back
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        isModalVisible
          ? 'mkt-absolute mkt-w-screen mkt-h-screen mkt-bg-[rgba(0,0,0,0.5)] mkt-z-[99999] mkt-overflow-hidden mkt-flex mkt-justify-center mkt-items-center  mkt-top-[-88px] lg:mkt-top-[-51px]'
          : 'mkt-hidden',
      )}
    >
      <div className="mkt-w-[90%] sm:mkt-w-[512px] mkt-h-auto mkt-min-h-[200px] sm:mkt-gap-4 mkt-bg-white mkt-rounded-md mkt-shadow-md mkt-p-3  sm:mkt-p-4 mkt-flex mkt-flex-col mkt-gap-3">
        <div className="mkt-text-center mkt-flex mkt-flex-col mkt-gap-1  mkt-tracking-[0.45px]">
          <h1 className="mkt-font-bold mkt-text-lg mkt-text-grey">
            View Primary Offerings
          </h1>
          <p className="mkt-text-sm mkt-text-grey">
            Due to regulations, we cannot proactively market securities to users
            in your country. However, you can view information about our issuers
            if you confirm your interest using the button below.
          </p>
          <p className="mkt-text-xs mkt-text-grey">
            By confirming, you acknowledge that your request comes without being
            solicited or approached, directly or indirectly, by the issuers
            therein or any affiliate acting as an agent on their behalf.
          </p>
        </div>
        <div className="mkt-flex mkt-flex-col mkt-gap-2 sm:mkt-flex-row">
          <div className="sm:mkt-order-2 mkt-w-full mkt-flex-col mkt-flex sm:mkt-w-[240px] mkt-justify-center mkt-items-center">
            <button
              onClick={consentHandler}
              className="mkt-px-[30px] mkt-py-[11px] mkt-flex mkt-justify-center mkt-items-center mkt-rounded-3xl mkt-bg-gold mkt-text-white mkt-w-full mkt-font-medium mkt-tracking-[0.4px] mkt-transition-all hover:mkt-bg-white hover:mkt-border hover:mkt-border-gold hover:mkt-text-gold"
            >
              Confirm Interest
            </button>
          </div>
          <div className=" sm:mkt-order-1 mkt-w-full mkt-flex-col mkt-flex sm:mkt-w-[50%]">
            <button
              onClick={rejectHandler}
              className="mkt-px-[30px] mkt-border mkt-border-light-grey mkt-py-[11px] mkt-flex mkt-justify-center mkt-items-center mkt-rounded-3xl mkt-bg-white mkt-text-grey mkt-w-full mkt-font-medium mkt-tracking-[0.4px] hover:mkt-brightness-90 mkt-transition-all"
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
