import { FC } from 'react';
import { MktSwitcher } from '@securitize/reactjs-mkt-ui';
import { PrimaryMarketTab } from './Tabs/PrimaryMarketTab';
import { SecondaryMarketTab } from './Tabs/SecondaryMarketTab';

export interface MarketsSwitcherProps {
  firstTabLabel: string;
  secondTabLabel: string;
}

export const MarketsSwitcher: FC<MarketsSwitcherProps> = ({
  firstTabLabel,
  secondTabLabel,
}) => {
  return (
    <MktSwitcher
      tabs={[
        {
          label: firstTabLabel,
          content: <PrimaryMarketTab />,
        },
        {
          label: secondTabLabel,
          content: <SecondaryMarketTab />,
        },
      ]}
    />
  );
};
