/* eslint-disable array-callback-return */
import { FC, useEffect, useState } from 'react';
import {
  MktOfferingTable,
  MktOfferingTableHeaders,
  MktOfferingTableRowValues,
} from '@securitize/reactjs-mkt-ui';
import { getOfferingGroups } from '../../data/builderio/offering-table/get-offering-groups';

export const OfferingTable: FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as MktOfferingTableRowValues[]);
  const [tableHeaders, setTableHeaders] = useState(
    [] as MktOfferingTableHeaders[],
  );

  useEffect(() => {
    const tableRows: any[] = [];

    getOfferingGroups().then(headers => {
      headers.map(header => {
        header.data.features.map(({ feature }: any) => {
          if (!tableRows.some(row => row.id === feature.value.id)) {
            tableRows.push(feature.value);
          }
        });
      });

      tableRows.map(row => {
        if (!row['data']['id']) {
          row['data']['id'] = row.id;
        }

        return setTableRows(oldValue => {
          return [...oldValue, row.data];
        });
      });

      const newHeaders = headers.reduce((any: any[], curr: any) => {
        const ids = curr.data.features.map(
          (feature: any) => feature.feature.id,
        );
        curr.data.rowsValues = ids;
        delete curr.data.features;
        any.push(curr.data);
        return any;
      }, []);

      setTableHeaders(newHeaders);
    });
    setLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <MktOfferingTable tableHeaders={tableHeaders} tableRows={tableRows} />;
};
