import { create } from 'zustand';
import { IInitialBlogValues } from './blog.types';
import {
  getAllArticles,
  getOneArticle,
} from '../../data/builderio/blog/get-blog-articles';

export const useBlogStore = create<IInitialBlogValues>(set => ({
  allArticles: [],
  isAllArticlesLoading: false,
  getAllArticles: async (blogName: string) => {
    set({ isAllArticlesLoading: true });
    const allBlogs = await getAllArticles({ articleName: blogName });
    set({ allArticles: await allBlogs });
    set({ isAllArticlesLoading: false });
  },
  relatedArticles: [],
  isRelatedArticlesLoading: false,
  singleArticle: undefined,
  isSingleLoading: true,
  getOneArticle: async (articleId: string | null) => {
    set({ isSingleLoading: true });
    const singleArticle = await getOneArticle(articleId);
    set({ singleArticle: await singleArticle });
    set({ isSingleLoading: false });
  },
}));
