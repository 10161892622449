import builder from '@builder.io/react';
import { BuilderModels } from '../../../helpers/constants';
import { PressArticleModel } from './press-article.model';

export const getAllExternalPressArticles = async (): Promise<
  PressArticleModel[]
> => {
  return builder.getAll(BuilderModels.EXTERNAL_PRESS_ARTICLE, {
    fields: 'data',
    options: {
      noTargeting: true,
      omit: 'name,data.blocks,data.state,data.inputs,data.customFonts',
      sort: {
        data: { date: -1 },
      },
    },
  });
};
