/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from '../../../hooks/useForm';
import { IInitialFormValuesTypes } from '../../../global/types/form.types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import type { InitialFormattedFormValuesTypes } from '../../../global/types/form.types';
import { blogConstants } from '../../../helpers/constants';
import { uriCodeHelper } from '../../../helpers/uri-helpers';
import learnBlogFigure from '../../../assets/images/learn-section-blog-figure.svg';
import { LearnLayout } from '../../../layout/LearnLayout/LearnLayout';
import { useBlogStore } from '../../../store/blog/blog.store';

interface BlogHeaderProps {
  initialBlogValues: InitialFormattedFormValuesTypes;
}

export const BlogHeader: React.FC<BlogHeaderProps> = ({
  initialBlogValues,
}) => {
  const { t } = useTranslation();

  const { getAllArticles } = useBlogStore(state => state);

  const [searchParams, setSearchParam] = useSearchParams();

  const searchParamHandler = (keyName: string, value: string) => {
    if (!value) {
      searchParams.delete(keyName);
      return setSearchParam(searchParams);
    } else if (!searchParams.get(keyName)) {
      searchParams.append(keyName, uriCodeHelper('encode', value));
      return setSearchParam(searchParams);
    } else {
      searchParams.set(keyName, uriCodeHelper('encode', value));
      return setSearchParam(searchParams);
    }
  };

  const submitHandler = (value: { 'search-blog': string }) => {
    searchParamHandler(
      blogConstants.BLOG_SEARCH_PARAM_NAME,
      value['search-blog'],
    );
    getAllArticles(value['search-blog']);
    document.getElementById('remove-focus')?.focus();
  };

  const Formik = useForm(initialBlogValues, submitHandler);

  return (
    <LearnLayout
      title={t('blogPage.blogHeader.title')}
      subtitle={t('blogPage.blogHeader.subtitle')}
      image={learnBlogFigure}
    >
      <form
        className="mkt-flex mkt-gap-1 mkt-w-full mkt-justify-center"
        onSubmit={Formik.handleSubmit}
      >
        {initialBlogValues.map((field: IInitialFormValuesTypes) => {
          return (
            <input
              className="mkt-h-[48px] placeholder:mkt-text-blue-dark mkt-shadow-inset-input mkt-inset-9 mkt-rounded-md mkt-w-full mkt-py-2 mkt-px-3"
              placeholder={t('blogPage.blogHeader.placeholderSearchInput')}
              type="search"
              key={field['name']}
              name={field['name']}
              id={field.id}
              onChange={Formik.handleChange}
              onBlur={Formik.handleBlur}
              value={Formik.values[field['name']]}
              enterKeyHint="search"
            />
          );
        })}
        <div
          id="remove-focus"
          tabIndex={-1}
          style={{ position: 'absolute', left: '-9999px' }}
        />
      </form>
    </LearnLayout>
  );
};
