/* eslint-disable react-hooks/exhaustive-deps */
import { BuilderComponent } from '@builder.io/react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { BuilderModels, LocalRoutesEnum } from '../../../helpers/constants';
import { routePattern } from '../../../helpers/navigation.helpers';
import { useEffect } from 'react';
import { SectionLayout } from '../../../layout/SectionLayout/SectionLayout';
import { ScrollToTop } from '../../../components/ScrollToTop/ScrollToTop';
import { useTranslation } from 'react-i18next';
import { ArticleHeader } from '../../../components/ArticleHeader/ArticleHeader';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { scrollToTop } from '../../../helpers/utils-helpers';

import { usePressStore } from '../../../store/press/press.store';

export const PressArticle = () => {
  const { t } = useTranslation();

  const { articleId } = useParams<{ articleId: string }>();

  const { singlePressArticle, isSinglePressLoading, getOnePressArticle } =
    usePressStore();

  useEffect(() => {
    getOnePressArticle(articleId || null);
  }, [articleId]);

  useDocumentTitle(singlePressArticle?.data?.title);

  useEffect(() => {
    scrollToTop();
  }, []);

  const currentUrlPath = useLocation().pathname;

  if (
    routePattern().test(currentUrlPath) &&
    !singlePressArticle &&
    !isSinglePressLoading
  ) {
    return <Navigate to={'/404'} />;
  }

  return (
    <>
      <SectionLayout
        isLoading={isSinglePressLoading || !singlePressArticle}
        className="mkt-px-3 mkt-pt-[48px]"
      >
        <div className="mkt-max-w-[900px] mkt-mx-auto">
          <ArticleHeader
            title={singlePressArticle?.data?.title}
            subtitle={singlePressArticle?.data?.subtitle}
            author={singlePressArticle?.data?.author}
            date={singlePressArticle?.data?.date}
            backToLabel={t('pressPage.pressArticle.backToIndex')}
            backTo={`${LocalRoutesEnum.PRESS}`}
          />
          <BuilderComponent
            model={BuilderModels.PRESS_ARTICLE}
            content={singlePressArticle}
            options={{ includeRefs: false }}
          />
          <ScrollToTop />
        </div>
      </SectionLayout>
    </>
  );
};
