export const uriCodeHelper = (
  type: 'decode' | 'encode',
  value: string | null,
): string => {
  if (type === 'encode' && value) {
    return encodeURIComponent(value);
  } else if (type === 'decode' && value) {
    return decodeURIComponent(value);
  } else {
    return '';
  }
};
