import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { formatDate } from '../../helpers/date-helpers';

interface ArticleHeaderProps {
  title: string;
  subtitle?: string;
  author?: string;
  date?: string;
  className?: string;
  backTo: string;
  backToLabel: string;
  readTime?: string;
}

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  author,
  date,
  title,
  backTo,
  className,
  subtitle,
  backToLabel,
  readTime,
}) => {
  return (
    <div className="mkt-flex mkt-flex-col mkt-gap-5 mkt-py-7">
      <nav
        className={clsx(
          'mkt-border-b-[1px] mkt-border-[#EEEEEE] mkt-pb-[9px]',
          className,
        )}
      >
        <Link
          to={backTo}
          className="mkt-font-sans mkt-text-sm mkt-inline-flex mkt-items-center mkt-justify-between mkt-text-[#665A61] mkt-pb-[2px] mkt-gap-2 hover:mkt-border-b-[1px] hover:mkt-border-accent-500 hover:mkt-pb-[-1px]"
        >
          <i className="wh-icon wh-icon-arrow-left mkt-translate-y-[-0.7px]" />
          <p>{backToLabel}</p>
        </Link>
      </nav>
      <div className="lg:mkt-flex lg:mkt-items-start lg:mkt-gap-4">
        <div>
          <h1 className="mkt-font-sans mkt-text-3xl xl:mkt-text-3xl mkt-text-grey mkt-font-medium mkt-tracking-tight">
            {title}
          </h1>
          {subtitle && (
            <h2 className="mkt-mt-1 mkt-font-sans mkt-text-lg xl:mkt-text-[20px] mkt-text-neutral-600 mkt-font-regular mkt-tracking-tight">
              {subtitle}
            </h2>
          )}
          <div className="mkt-mt-[20px] mkt-flex">
            <p className="mkt-font-sans mkt-text-base mkt-text-accent-600 mkt-whitespace-pre">
              {author && (
                <b className="mkt-text-grey mkt-font-medium">
                  {author}
                  {`  `}
                  {readTime || date ? (
                    <span className="mkt-font-regular">•</span>
                  ) : (
                    ''
                  )}
                </b>
              )}
              {date && (
                <span className="mkt-text-grey">
                  {`  `}
                  {formatDate(date)}
                  {`  `}
                  {readTime && '•'}
                </span>
              )}
              {readTime && (
                <span className="mkt-text-grey">
                  {`  `}
                  {readTime} read
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
