import React from 'react';
import { SectionLayout } from '../../../layout/SectionLayout/SectionLayout';
import { BlogArticleModel } from '../../../data/builderio/blog/blog-article.model';
import { MktCard } from '@securitize/reactjs-mkt-ui';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { NoArticlesFound } from '../../../components/NoArticleFound/NoArticleFound';
import { formatDate } from '../../../helpers/date-helpers';
import { LocalRoutesEnum } from '../../../helpers/constants';

interface BlogIndexProps {
  allArticles: BlogArticleModel[];
  isLoading?: boolean;
}

export const BlogIndex: React.FC<BlogIndexProps> = ({
  allArticles,
  isLoading,
}) => {
  return (
    <SectionLayout
      className="mkt-px-3 lg:mkt-px-5 mkt-w-full mkt-bg-white"
      isLoading={isLoading}
    >
      <div className="sm:mkt-max-w-full mkt-mx-auto lg:mkt-max-w-[1200px] mkt-flex mkt-justify-center  mkt-flex-col mkt-gap-[30px] mkt-pb-[40px] mkt-pt-[65px]  lg:mkt-pt-[110px]">
        {allArticles.length >= 1 ? (
          <ul
            className={clsx(
              'mkt-w-full',
              `${
                allArticles.length > 1
                  ? 'mkt-grid mkt-grid-cols-1 md:mkt-grid-cols-2 xl:mkt-grid-cols-3 mkt-gap-y-5 mkt-gap-x-1 xl:mkt-gap-x-5 xl:mkt-gap-y-5'
                  : 'mkt-flex mkt-justify-center'
              }`,
            )}
          >
            {allArticles.map((article, index) => {
              return (
                <li
                  className={clsx(
                    `hover:mkt-translate-y-[-10px] mkt-transition-all mkt-w-full mkt-h-full mkt-flex mkt-justify-center `,
                    'xl:mkt-px-[5px]',
                  )}
                  key={index}
                >
                  <Link
                    to={`${LocalRoutesEnum.BLOG}/${article.data?.handle}`}
                    className="mkt-w-full mkt-h-full mkt-flex mkt-justify-center"
                  >
                    <MktCard
                      title={article.data?.title}
                      image={article.data?.image}
                      date={formatDate(article.data?.date)}
                      description={article.data?.subtitle}
                      variant="dark-secondary"
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : (
          <NoArticlesFound />
        )}
      </div>
    </SectionLayout>
  );
};
