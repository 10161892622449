import { Builder } from '@builder.io/react';
import { MktFeaturedOfferBox } from '@securitize/reactjs-mkt-ui';
import { BuilderComponents } from '../../helpers/constants';

Builder.registerComponent(MktFeaturedOfferBox, {
  name: BuilderComponents.FEATURED_OFFER_BOX,
  inputs: [
    {
      friendlyName: 'Title',
      name: 'title',
      type: 'string',
      required: true,
    },
    {
      friendlyName: 'Subtitle',
      name: 'subtitle',
      type: 'string',
      required: false,
    },
    {
      friendlyName: 'Description',
      name: 'description',
      type: 'string',
      required: false,
    },
    {
      friendlyName: 'Field Type',
      name: 'fieldType',
      type: 'string',
      defaultValue: 'box',
      enum: ['box', 'number'],
      required: false,
    },
    {
      friendlyName: 'Border Type',
      name: 'borderType',
      type: 'string',
      defaultValue: 'short',
      enum: ['full', 'short'],
      required: false,
    },
  ],
});
