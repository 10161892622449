import builder from '@builder.io/react';
import { BuilderModels } from '../../../helpers/constants';
import { PressArticleModel } from './press-article.model';

export const getAllPressArticles = async ({
  audienceId,
}: {
  audienceId?: string | undefined;
}): Promise<PressArticleModel[]> => {
  return builder.getAll(BuilderModels.PRESS_ARTICLE, {
    fields: 'data',
    limit: 100,
    options: {
      noTargeting: true,
      omit: 'name,data.blocks,data.state,data.inputs,data.customFonts',
      sort: {
        data: { date: -1 },
      },
    },
    query: {
      data: {
        audience: {
          id: audienceId,
        },
      },
    },
  });
};

export const getOnePressArticle = async (
  articleId: string | null,
): Promise<PressArticleModel> => {
  return builder
    .get(BuilderModels.PRESS_ARTICLE, {
      options: {},
      query: {
        data: {
          handle: articleId,
        },
      },
    })
    .promise();
};
