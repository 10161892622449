/* eslint-disable react-hooks/exhaustive-deps */
import { BlogIndex } from './BlogSection/BlogIndex';
import { BlogHeader } from './BlogHeader/BlogHeader';
import { useEffect } from 'react';
import { PageProps } from '../../global/types/page.types';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useBlogSearchParams } from '../../hooks/useBlogSearchParams';
import { useBlogStore } from '../../store/blog/blog.store';

export const Blog: React.FC<PageProps> = ({ pageTitle }) => {
  useDocumentTitle(pageTitle);

  const { allArticles, getAllArticles, isAllArticlesLoading } = useBlogStore(
    state => state,
  );

  const { blogSearchParam, initialBlogValues } = useBlogSearchParams();

  useEffect(() => {
    getAllArticles(blogSearchParam || '');
  }, []);

  return (
    <div>
      <BlogHeader initialBlogValues={initialBlogValues} />
      <BlogIndex isLoading={isAllArticlesLoading} allArticles={allArticles} />
    </div>
  );
};
