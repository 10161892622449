import { Builder } from '@builder.io/react';
import { BuilderComponents, BuilderModels } from '../../helpers/constants';
import { BlogArticleQuote } from './BlogArticleQuote';

Builder.registerComponent(BlogArticleQuote, {
  name: BuilderComponents.BLOG_ARTICLE_QUOTE,
  models: [BuilderModels.BLOG_ARTICLE, BuilderModels.PRESS_ARTICLE],
  inputs: [
    {
      friendlyName: 'Text',
      name: 'text',
      type: 'text',
      defaultValue: 'Example text...',
      required: true,
    },
  ],
});
