import '../helpers/builder-settings';
import { Route, Routes, useLocation } from 'react-router-dom';
import { navigationRoutes } from './navigation-routes';
import { INavigationRoutes } from '../global/types/navigation.types';
import { PageLayout } from '../layout/PageLayout/PageLayout';
import { AnimatePresence } from 'framer-motion';

/**
 * @returns Render Routes
 */
const renderRoutes = () => {
  return navigationRoutes.map((route: INavigationRoutes, i) => (
    <Route
      key={i}
      path={route.path}
      element={<route.Component {...route.props} />}
    />
  ));
};

/**
 *
 * @returns Router App
 */
export const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <PageLayout>
        <Routes location={location}>{renderRoutes()}</Routes>
      </PageLayout>
    </AnimatePresence>
  );
};
