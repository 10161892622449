import { SectionLayout } from '../../../layout/SectionLayout/SectionLayout';
import { useTranslation } from 'react-i18next';
import { LearnLayout } from '../../../layout/LearnLayout/LearnLayout';
import blogImage from '../../../assets/images/learn-section-blog-figure.svg';

export const PressHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionLayout className="mkt-bg-secondary-100 mkt-h-auto mkt-relative">
      <LearnLayout
        title={t('pressPage.pressHeader.title')}
        subtitle={t('pressPage.pressHeader.description')}
        image={blogImage}
      />
    </SectionLayout>
  );
};
