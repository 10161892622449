import React from 'react';
import { MktFooter } from '@securitize/reactjs-mkt-ui';
import { FooterLinkModel } from '../../data/builderio/footer/footer-link.model';
import { footerConstants } from '../../helpers/constants';
import sipcLogo from '@securitize/assets-mkt-ui/dist/logos/svg/sipc-logo.svg';
import brokerCheckLogo from '@securitize/assets-mkt-ui/dist/logos/svg/brokerCheck-logo.svg';
import secLogoNegative from '@securitize/assets-sec-ui/dist/logos/svg/sec-logo-negative.svg';
import youtubeIcon from '@securitize/assets-mkt-ui/dist/social/svg/youtube-social.svg';
import twitterIcon from '@securitize/assets-mkt-ui/dist/social/svg/twitter-social.svg';
import linkedinIcon from '@securitize/assets-mkt-ui/dist/social/svg/linkedin-social.svg';
import appStoreLogo from '@securitize/assets-mkt-ui/dist/logos/svg/appStore-logo.svg';

interface FooterProps {
  footerLinks: FooterLinkModel[];
}

export const Footer: React.FC<FooterProps> = ({ footerLinks }) => {
  const brandLogos = [
    {
      logo: sipcLogo,
      url: 'https://www.sipc.org/',
      title: 'SPIC 2',
    },
    {
      logo: brokerCheckLogo,
      url: 'https://brokercheck.finra.org/',
      title: 'BrokerCheck 3',
    },
  ];

  const appStoreLink = {
    url: 'https://apps.apple.com/us/app/securitize/id1659870004',
    icon: appStoreLogo,
    title: 'Download from the App Store',
  };

  const socialLinks = [
    {
      icon: linkedinIcon,
      url: 'https://www.linkedin.com/company/securitize/',
      title: 'Linkedin',
    },
    {
      icon: twitterIcon,
      url: 'https://twitter.com/Securitize?utm_source=Markets&utm_medium=referral',
      title: 'Twitter',
    },
    {
      icon: youtubeIcon,
      url: 'https://www.youtube.com/c/Securitize/featured?utm_source=Markets&utm_medium=referral',
      title: 'Youtube',
    },
  ];

  const navigationLinks: any[] =
    footerLinks &&
    footerLinks?.map(({ data }: any, index) => {
      return {
        title: data.label,
        index,
        links: data.links?.map((link: any, index: number) => {
          return {
            label: link.label,
            url: link.url,
            index,
          };
        }),
      };
    });

  return (
    <MktFooter
      navigationLinks={navigationLinks}
      brandLogos={brandLogos}
      socialLinks={socialLinks}
      appStoreLink={appStoreLink}
      footerLogo={secLogoNegative}
      copyrightInfo={footerConstants.COPYRIGHT}
      emailContact={footerConstants.EMAIL_CONTACT}
      showDisclaimer={true}
      showSocialAndAppStoreLinks={true}
    />
  );
};

export const MemoizedFooter = React.memo(Footer);
