import { Builder } from '@builder.io/react';
import { BlogArticleText } from './BlogArticleText';
import { BuilderComponents, BuilderModels } from '../../helpers/constants';

Builder.registerComponent(BlogArticleText, {
  name: BuilderComponents.BLOG_ARTICLE_TEXT,
  models: [BuilderModels.BLOG_ARTICLE, BuilderModels.PRESS_ARTICLE],
  inputs: [
    {
      friendlyName: 'Text',
      name: 'text',
      type: 'richText',
      defaultValue: 'Example text...',
      required: true,
    },
  ],
});
