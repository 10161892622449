import {
  InitialFormattedFormValuesTypes,
  IInitialFormValuesTypes,
} from '../global/types/form.types';

interface IFormatFormValuesAcc {
  [keyName: string]: string;
}

export const formatInitialValues = (
  values: InitialFormattedFormValuesTypes,
) => {
  const formattedValues = values.reduce(
    (acc: IFormatFormValuesAcc, curr: IInitialFormValuesTypes) => {
      if (!acc[curr.name]) {
        acc[curr.name] = curr?.value ?? '';
      }
      return acc;
    },
    {},
  );

  return formattedValues;
};
