import builder from '@builder.io/react';
import { BuilderModels } from '../../../helpers/constants';
import { BannerModel } from './banner.model';

export const getBanner = async (): Promise<BannerModel> => {
  const response = await builder.getAll(BuilderModels.BANNER, {
    options: {
      noTargeting: true,
      omit: 'lastUpdated,lastUpdatedBy,rev,createdBy,firstPublished,name,variations,testRatio,query',
      sort: {
        createdDate: 1,
      },
      query: {
        'published.$eq': 'published',
        'data.website.$eq': 'main',
      },
    },
  });
  return response?.at(-1)?.data;
};
