import marketingApi from '../gatewayApi';
import { AssetModel } from './asset.model';

interface SecondaryMarketResponse {
  data: AssetModel[];
}

export const getAssets = async (): Promise<SecondaryMarketResponse> => {
  const { data } = await marketingApi.get('/market-summary');
  return data;
};
