import { useEffect, useState } from 'react';
import {
  MktSwitcherTabTable,
  MktSwitcherTabTableHeaderProps,
  MktSwitcherTabTableRowProps,
} from '@securitize/reactjs-mkt-ui';
import { AssetModel } from '../../../data/api/secondary-market/asset.model';
import { getAssets } from '../../../data/api/secondary-market/get-assets';

export const SecondaryMarketTab = () => {
  const [assets, setAssets] = useState([] as AssetModel[]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    getAssets().then(data => {
      setAssets(data.data);
      setIsLoading(false);
    });
  }, []);

  assets.splice(5, 4);

  const tableHeader: MktSwitcherTabTableHeaderProps[] = [
    { title: 'Issuer', dataIndex: 'issuerName', actionIndex: 'actionLink' },
    { title: 'Nav Price', dataIndex: 'navPrice', primary: true },
    { title: 'Last Price', dataIndex: 'lastPrice' },
    { title: '24H Change', dataIndex: 'dailyChange' },
    { title: 'Action', dataIndex: 'actionLink' },
  ];

  const tableRows: MktSwitcherTabTableRowProps[] = assets.map(
    (asset: AssetModel) => {
      return {
        issuerName: {
          type: 'icon',
          label: asset.issuerName,
          iconUrl: asset.logoUrl,
        },
        navPrice: {
          type: 'text',
          label: asset.lastPrice?.toString() || 'N/A',
        },
        lastPrice: {
          type: 'text',
          label: asset.lastPrice?.toString() || 'N/A',
        },
        dailyChange: {
          type: 'text',
          label: asset.lastPrice?.toString() || 'N/A',
        },
        actionLink: {
          type: 'button',
          label: 'Trade',
          linkUrl: 'https://securitize.io/',
        },
      };
    },
  );

  return (
    <MktSwitcherTabTable
      header={tableHeader}
      rows={tableRows}
      isLoading={isLoading}
    />
  );
};
