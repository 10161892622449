import { FC } from 'react';

export interface BlogArticleQuoteProps {
  text: string;
}

export const BlogArticleQuote: FC<BlogArticleQuoteProps> = ({ text }) => {
  return (
    <p className="mkt-font-regular mkt-text-[24px] mkt-text-blue-medium mkt-text-start mkt-mx-[45px] mkt-pl-1 mkt-leading-[32px] mkt-border-l-[4px]">
      {text}
    </p>
  );
};
