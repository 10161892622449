import { FC } from 'react';

export interface BlogArticleSubtitleProps {
  text: string;
}

export const BlogArticleSubtitle: FC<BlogArticleSubtitleProps> = ({ text }) => {
  return (
    <h2 className="mkt-font-sans mkt-font-bold mkt-text-[24px] mkt-text-grey">
      {text}
    </h2>
  );
};
