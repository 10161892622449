import { useEffect, useState } from 'react';
import {
  MktSwitcherTabList,
  MktSwitcherTabListItemProps,
} from '@securitize/reactjs-mkt-ui';
import { OpportunityModel } from '../../../data/api/primary-market/opportunity.model';
import { getOpportunities } from '../../../data/api/primary-market/get-opportunities';

export const PrimaryMarketTab = () => {
  const [opportunities, setOpportunities] = useState([] as OpportunityModel[]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    getOpportunities().then(data => {
      setOpportunities(data);
      setIsLoading(false);
    });
  }, []);

  opportunities.splice(3, 4);

  const listItems: MktSwitcherTabListItemProps[] = opportunities.map(
    (opportunity: OpportunityModel) => {
      return {
        key: opportunity.id,
        title: opportunity.title,
        description: opportunity.description,
        image: opportunity.image,
        linkUrl: opportunity.primaryMarketUrl,
      };
    },
  );

  return <MktSwitcherTabList items={listItems} isLoading={isLoading} />;
};
