import { Builder, withChildren } from '@builder.io/react';
import { MktFeaturedOffer } from '@securitize/reactjs-mkt-ui';
import { BuilderComponents } from '../../helpers/constants';

const FeaturedOfferWithChildren = withChildren(MktFeaturedOffer);

Builder.registerComponent(FeaturedOfferWithChildren, {
  name: BuilderComponents.FEATURED_OFFER,
  childRequirements: {
    message: 'You must put a custom box or a basic box',
    // query: {
    //   'component.name': { $in: ['Featured Offer Box', 'Box'] },
    // },
  },
  inputs: [
    {
      friendlyName: 'Header',
      name: 'header',
      type: 'object',
      required: true,
      subFields: [
        {
          friendlyName: 'Background',
          name: 'backgroundImage',
          type: 'file',
          required: true,
          allowedFileTypes: ['jpeg', 'png'],
        },
        {
          friendlyName: 'Logo',
          name: 'logo',
          type: 'file',
          required: true,
          allowedFileTypes: ['jpeg', 'png'],
        },
        {
          friendlyName: 'Title',
          name: 'title',
          type: 'string',
          required: false,
        },
        {
          friendlyName: 'Subtitle',
          name: 'subtitle',
          type: 'string',
          required: false,
        },
      ],
    },
  ],
});
