import { scrollToTop } from '../../helpers/utils-helpers';

export const ScrollToTop: React.FC = () => {
  return (
    <div className="mkt-my-6 lg:mkt-my-7 mkt-h-[36px] mkt-w-[36px] mkt-mx-auto hover:mkt-brightness-95 mkt-transition-all hover:mkt-translate-y-[-4px]">
      <button
        className="mkt-shadow-md mkt-flex mkt-justify-center mkt-items-center mkt-cursor-pointer mkt-w-full mkt-h-full mkt-bg-neutral-100"
        onClick={scrollToTop}
        aria-label="Scroll back to top button"
      >
        <i className="wh-icon wh-icon-arrow-up mkt-text-xl mkt-text-[#0283AB]" />
      </button>
    </div>
  );
};
