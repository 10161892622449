import React from 'react';

interface LearnSectionProps {
  image: string;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export const LearnLayout: React.FC<LearnSectionProps> = ({
  children,
  subtitle,
  title,
}) => {
  return (
    <div className="mkt-bg-cover mkt-gap-4 mkt-bg-center  mkt-w-full mkt-pt-[160px] mkt-pb-[90px] mkt-px-[20px] mkt-bg-white-off">
      <div className="mkt-flex mkt-flex-col  lg:mkt-flex-row mkt-max-w-[1200px] lg:mkt-mx-auto">
        <div className="mkt-flex mkt-flex-col mkt-gap-3 lg:mkt-pt-0 lg:mkt-gap-6 mkt-w-full xl:mkt-mx-auto mkt-max-h-[600px] mkt-order-2 lg:mkt-order-1 mkt-items-center lg:mkt-justify-center">
          <div className="lg:mkt-mt-0 mkt-text-center">
            <h1 className="mkt-text-[38px] sm:mkt-mt-[15px] mkt-leading-[44px] mkt-justify-center mkt-tracking-normal sm:mkt-min-h-[80px] mkt-flex mkt-items-center mkt-mb-[22px] sm:mkt-text-[70px] mkt-text-blue-dark  mkt-font-bold sm:mkt-leading-[80px] mkt-font-serif">
              {title}
            </h1>
            <p className="mkt-text-lg sm:mkt-text-[20px] mkt-text-blue-dark mkt-font-regular mkt-tracking-[0.2px]">
              {subtitle}
            </p>
          </div>
          <div className="mkt-flex mkt-flex-col mkt-gap-2 mkt-flex-wrap mkt-w-full md:mkt-max-w-[690px]">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
