import { AxiosInstance } from 'axios';
import { configs } from '../../helpers/constants';
import { createApiAxios } from './createApiAxios';

let axiosApi = createApiAxios(configs.MARKETING_API_BASE_URL);

const marketingApi = new Proxy<AxiosInstance>(axiosApi, {
  get: (_target, property: keyof AxiosInstance) => axiosApi[property],
});

export default marketingApi;
