import axios from 'axios';

export const createApiAxios = (url: string) => {
  const baseURL = `${url}`;
  const apiAxios = axios.create({
    baseURL,
    // withCredentials: true,
    headers: {
      Accept: 'application/json',
      // 'Content-Type': 'application/json',
    },
  });

  return apiAxios;
};
