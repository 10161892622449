/* eslint-disable react-hooks/exhaustive-deps */
import { BuilderComponent } from '@builder.io/react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { BuilderModels, LocalRoutesEnum } from '../../../helpers/constants';
import { routePattern } from '../../../helpers/navigation.helpers';
import { useEffect } from 'react';
import { SectionLayout } from '../../../layout/SectionLayout/SectionLayout';
import { ScrollToTop } from '../../../components/ScrollToTop/ScrollToTop';
import { useTranslation } from 'react-i18next';
import { ArticleHeader } from '../../../components/ArticleHeader/ArticleHeader';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { scrollToTop } from '../../../helpers/utils-helpers';

import { useBlogStore } from '../../../store/blog/blog.store';

export const BlogArticle = () => {
  const { t } = useTranslation();

  const { articleId } = useParams<{ articleId: string }>();

  const { getOneArticle, isSingleLoading, singleArticle } = useBlogStore(
    state => state,
  );

  useEffect(() => {
    getOneArticle(articleId || null);
  }, [articleId]);

  useDocumentTitle(singleArticle?.data?.title);

  useEffect(() => {
    scrollToTop();
  }, []);

  const currentUrlPath = useLocation().pathname;

  if (
    routePattern().test(currentUrlPath) &&
    !singleArticle &&
    !isSingleLoading
  ) {
    return <Navigate to={'/404'} />;
  }

  return (
    <>
      <SectionLayout
        className="mkt-px-3 mkt-pt-[48px]"
        isLoading={isSingleLoading}
      >
        <div className="mkt-max-w-[900px] mkt-mx-auto">
          <ArticleHeader
            title={singleArticle?.data?.title}
            subtitle={singleArticle?.data?.subtitle}
            author={singleArticle?.data?.author}
            date={singleArticle?.data?.date}
            readTime={singleArticle?.data?.readTime}
            backToLabel={t('blogPage.blogArticle.backToIndex')}
            backTo={`${LocalRoutesEnum.BLOG}`}
          />
          <BuilderComponent
            model={BuilderModels.BLOG_ARTICLE}
            content={singleArticle}
            options={{ includeRefs: false }}
          />
          <ScrollToTop />
        </div>
      </SectionLayout>
    </>
  );
};
