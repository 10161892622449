import React from 'react';
import { SectionLayout } from '../../../layout/SectionLayout/SectionLayout';

import { MktCard } from '@securitize/reactjs-mkt-ui';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { formatDate } from '../../../helpers/date-helpers';
import { LocalRoutesEnum } from '../../../helpers/constants';
import { NoArticlesFound } from '../../../components/NoArticleFound/NoArticleFound';
import { PressArticleModel } from '../../../data/builderio/press/press-article.model';
import { ExternalPressArticleModel } from '../../../data/builderio/press/external-press-article.model';

interface PressIndexProps {
  allPressArticles: PressArticleModel[];
  allExternalPressArticles: ExternalPressArticleModel[];
  isLoading?: boolean;
}

export const PressIndex: React.FC<PressIndexProps> = ({
  allPressArticles,
  isLoading,
  allExternalPressArticles,
}) => {
  return (
    <SectionLayout
      className="mkt-px-3 lg:mkt-px-5 mkt-w-full"
      isLoading={isLoading}
    >
      <div className="sm:mkt-max-w-full mkt-mx-auto lg:mkt-max-w-[1200px] mkt-flex mkt-justify-center  mkt-flex-col mkt-gap-[30px] mkt-pb-[40px] mkt-pt-[65px]  lg:mkt-pt-[110px]">
        <h1 className="mkt-text-[24px] mkt-text-start  sm:mkt-text-[35px] mkt-font-[600] mkt-text-blue-dark lg:mkt-text-start mkt-font-serif">
          Press Releases
        </h1>
        {allPressArticles.length >= 1 ? (
          <ul
            className={clsx(
              'mkt-w-full',
              `${
                allPressArticles.length > 1
                  ? 'mkt-grid mkt-grid-cols-1 md:mkt-grid-cols-2 xl:mkt-grid-cols-2 mkt-gap-y-5 mkt-gap-x-1 xl:mkt-gap-x-5 xl:mkt-gap-y-5'
                  : 'mkt-flex mkt-justify-center'
              }`,
            )}
          >
            {allPressArticles.map((article, index) => {
              return (
                <li
                  className={clsx(
                    `hover:mkt-translate-y-[-10px] mkt-transition-all mkt-w-full mkt-h-full mkt-flex mkt-justify-center `,
                    'xl:mkt-px-[5px]',
                  )}
                  key={index}
                >
                  <Link
                    to={`${LocalRoutesEnum.PRESS}/${article.data?.handle}`}
                    className="mkt-w-full mkt-h-full mkt-flex mkt-justify-center"
                  >
                    <MktCard
                      title={article.data?.title}
                      image={article.data?.image}
                      date={formatDate(article.data?.date)}
                      description={article.data?.subtitle}
                      variant="dark-primary"
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : (
          <NoArticlesFound />
        )}
      </div>
      {allExternalPressArticles.length >= 1 && (
        <div className="sm:mkt-max-w-full mkt-mx-auto mkt-flex mkt-justify-center lg:mkt-max-w-[1200px] mkt-flex-col mkt-gap-[30px] mkt-pb-[40px] mkt-pt-[65px] lg:mkt-pt-[110px]">
          <h1 className="mkt-text-[24px] mkt-text-start  sm:mkt-text-[35px] mkt-font-[600] mkt-text-blue-dark lg:mkt-text-start mkt-font-serif">
            Press Coverage
          </h1>
          <div className="mkt-grid mkt-grid-cols-1 md:mkt-grid-cols-2 xl:mkt-grid-cols-3 mkt-gap-y-5 mkt-gap-x-1 xl:mkt-gap-x-5 xl:mkt-gap-y-5">
            {allExternalPressArticles.map((article, index) => {
              return (
                <li
                  className={clsx(
                    `hover:mkt-translate-y-[-10px] mkt-transition-all mkt-w-full mkt-h-full mkt-flex mkt-justify-center `,
                    'xl:mkt-px-[5px]',
                  )}
                  key={index}
                >
                  <a
                    href={article.data?.link}
                    target="_blank"
                    className="mkt-w-full mkt-h-full mkt-flex mkt-justify-center"
                    rel="noreferrer"
                  >
                    <MktCard
                      title={article.data?.title}
                      logo={article.data?.logo}
                      date={formatDate(article.data?.date)}
                      variant="white"
                    />
                  </a>
                </li>
              );
            })}
          </div>
        </div>
      )}
    </SectionLayout>
  );
};
