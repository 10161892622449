import builder from '@builder.io/react';
import { BuilderModels } from '../../../helpers/constants';
import { RedirectUrlModel } from './redirect-url-model';

export const getUrlRedirects = async (): Promise<RedirectUrlModel[]> => {
  return await builder.getAll(BuilderModels.URL_REDIRECTS, {
    limit: 100,
    fields: 'data',
  });
};
