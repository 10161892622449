import { FC } from 'react';

export interface BlogArticleTextProps {
  text: string;
}

export const BlogArticleText: FC<BlogArticleTextProps> = ({ text }) => {
  return (
    <p
      className="mkt-font-sans mkt-font-regular mkt-text-[20px] mkt-text-grey"
      dangerouslySetInnerHTML={{ __html: text }}
    ></p>
  );
};
