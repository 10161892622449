import { Builder, withChildren } from '@builder.io/react';
import { MktFeaturedOffers } from '@securitize/reactjs-mkt-ui';
import { BuilderComponents } from '../../helpers/constants';

const FeaturedOffersWithChildren = withChildren(MktFeaturedOffers);

Builder.registerComponent(FeaturedOffersWithChildren, {
  name: BuilderComponents.FEATURED_OFFER_SLIDER,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: BuilderComponents.FEATURED_OFFER },
    },
  ],
  childRequirements: {
    message: 'You must put one or more Featured Offer component',
    query: {
      'component.name': { $in: [BuilderComponents.FEATURED_OFFER] },
    },
  },
});
