/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from 'react-router-dom';
import { uriCodeHelper } from '../helpers/uri-helpers';
import { useMemo } from 'react';
import { blogConstants } from '../helpers/constants';

export const useBlogSearchParams = () => {
  const [searchParams] = useSearchParams();

  const blogSearchParam = uriCodeHelper(
    'decode',
    searchParams.get(blogConstants.BLOG_SEARCH_PARAM_NAME),
  );

  const initialBlogValues = useMemo(() => {
    return [
      {
        label: 'Blog',
        id: 'search-blog',
        name: 'search-blog',
        value: blogSearchParam || '',
      },
    ];
  }, []);

  return { initialBlogValues, blogSearchParam };
};
