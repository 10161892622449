import builder from '@builder.io/react';
import { BuilderModels } from '../../../helpers/constants';
import { BlogArticleModel } from './blog-article.model';

export const getAllArticles = async ({
  audienceId,
  selectedTags,
  articleName,
  contentTypeId,
}: {
  audienceId?: string;
  selectedTags?: string[];
  articleName?: string;
  contentTypeId?: string;
}): Promise<BlogArticleModel[]> => {
  return builder.getAll(BuilderModels.BLOG_ARTICLE, {
    fields: 'data',
    limit: 100,
    options: {
      noTargeting: true,
      omit: 'name,data.blocks,data.state,data.inputs,data.customFonts',
      sort: {
        data: { date: -1 },
      },
    },
    query: {
      data: {
        tags: {
          reference: {
            id: { $in: selectedTags },
          },
        },
        audience: {
          id: audienceId,
        },
        showInIndex: true,
        title: { $regex: articleName, $options: 'i' },
        contentType: {
          reference: {
            id: contentTypeId,
          },
        },
      },
    },
  });
};

export const getRelatedBlogArticles = async ({
  selectedIds,
}: {
  selectedIds?: string[];
}): Promise<BlogArticleModel[]> => {
  return builder.getAll(BuilderModels.BLOG_ARTICLE, {
    limit: 3,
    options: {
      noTargeting: true,
      omit: 'name,data.blocks,data.state,data.inputs,data.customFonts',
      sort: {
        data: {
          date: -1,
        },
      },
    },

    query: {
      id: { $in: selectedIds },
    },
  });
};

export const getOneArticle = async (
  articleId: string | null,
): Promise<BlogArticleModel> => {
  return builder
    .get(BuilderModels.BLOG_ARTICLE, {
      options: {},
      query: {
        data: {
          handle: articleId,
        },
      },
    })
    .promise();
};
