import { Builder } from '@builder.io/react';
import { MarketsSwitcher } from './MarketsSwitcher';
import { BuilderComponents } from '../../helpers/constants';

Builder.registerComponent(MarketsSwitcher, {
  name: BuilderComponents.MARKETS_SWITCHER,
  image:
    'https://cdn.builder.io/api/v1/image/assets%2Fd39b51a544e84e2fbb2445f58c6c6f2c%2F03bba86ba94342469c8ef6a85c37ad22?width=500&height=500',
  inputs: [
    {
      friendlyName: 'First Tab Label',
      name: 'firstTabLabel',
      type: 'string',
      required: true,
    },
    {
      friendlyName: 'Second Tab Label',
      name: 'secondTabLabel',
      type: 'string',
      required: true,
    },
  ],
});
