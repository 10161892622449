import { useFormik } from 'formik';
import { formatInitialValues } from '../helpers/form-helpers';
import { InitialFormattedFormValuesTypes } from '../global/types/form.types';

export const useForm = (
  initialValues: InitialFormattedFormValuesTypes,
  dispatch: (payload?: any) => void,
  validationSchema?: any,
  resetFields?: boolean,
) => {
  const formattedInitialValues = formatInitialValues(initialValues);

  const formik = useFormik({
    initialValues: formattedInitialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(values);
      resetFields && resetForm();
    },
  });

  return formik;
};
