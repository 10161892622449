import { create } from 'zustand';
import { IInitialPressValues } from './press.types';
import {
  getAllPressArticles,
  getOnePressArticle,
} from '../../data/builderio/press/get-press-articles';
import { getAllExternalPressArticles } from '../../data/builderio/press/get-external-press-articles';

export const usePressStore = create<IInitialPressValues>(set => ({
  isSinglePressLoading: true,
  singlePressArticle: undefined,
  getOnePressArticle: async (articleId: string | null) => {
    set({ isSinglePressLoading: true });
    const singlePressArticle = await getOnePressArticle(articleId);
    set({ singlePressArticle: await singlePressArticle });
    set({ isSinglePressLoading: false });
  },
  allPressArticles: [],
  isAllPressArticlesLoading: true,
  getAllPressArticles: async () => {
    set({ isAllPressArticlesLoading: true });
    const allPressArticles = await getAllPressArticles({
      audienceId: undefined,
    });
    set({ allPressArticles: await allPressArticles });
    set({ isAllPressArticlesLoading: false });
  },
  allExternalPressArticles: [],
  isAllExternalPressArticlesLoading: true,
  getAllExternalPressArticles: async () => {
    set({ isAllExternalPressArticlesLoading: true });
    const allExternalPressArticles = await getAllExternalPressArticles();
    set({ allExternalPressArticles: await allExternalPressArticles });
    set({ isAllExternalPressArticlesLoading: false });
  },
}));
