import { create } from 'zustand';
import { getPageByUrl } from '../../data/builderio/page/get-page-by';
import { IInitialPageValues } from './page.types';
import { getFooterLinks } from '../../data/builderio/footer/get-footer-links';
import { getTopBarLinks } from '../../data/builderio/top-bar/get-top-bar';
import { getBanner } from '../../data/builderio/banner/get-banner';

export const usePageStore = create<IInitialPageValues>(set => ({
  isPageLoading: false,
  content: undefined,
  getPageContent: async (url: string) => {
    set({ isPageLoading: true });
    const pageContent = await getPageByUrl(url);
    set({ content: await pageContent });
    set({ isPageLoading: false });
  },
  isFooterLoading: false,
  footerLinks: [],
  getFooterLinks: async () => {
    set({ isFooterLoading: true });
    const footerLinks = await getFooterLinks();
    set({ footerLinks: await footerLinks });
    set({ isFooterLoading: false });
  },
  isTopBarLoading: false,
  topBarLinks: [],
  getTopbarLinks: async () => {
    set({ isTopBarLoading: true });
    const topBarLinks = await getTopBarLinks();
    set({ topBarLinks: await topBarLinks });
    set({ isTopBarLoading: false });
  },
  bannerContent: null,
  isBannerContentLoading: false,
  getBannerContent: async () => {
    set({ isBannerContentLoading: true });
    const bannerContent = await getBanner();
    set({ bannerContent: await bannerContent });
    set({ isBannerContentLoading: false });
  },
}));
