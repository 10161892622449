import { Builder } from '@builder.io/react';
import { Component } from '@builder.io/sdk';
import { MktTestimonial } from '@securitize/reactjs-mkt-ui';
import { BuilderComponents } from '../../helpers/constants';

const TestimonialBuilderConfig: Component = {
  name: BuilderComponents.TESTIMONIAL,
  image:
    'https://cdn.builder.io/api/v1/image/assets%2Fd39b51a544e84e2fbb2445f58c6c6f2c%2F7214d2d7a3ed48e2acbe65d5b40b29be?width=500&height=500',
  inputs: [
    {
      name: 'testimonial',
      type: 'object',
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'position',
          type: 'string',
          required: true,
        },
        {
          name: 'testimony',
          type: 'string',
          required: true,
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png'],
          required: true,
        },
      ],
    },
  ],
};

Builder.registerComponent(MktTestimonial, TestimonialBuilderConfig);
