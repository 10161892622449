import noArticlesFoundImg from '../../assets/images/no-articles-found.png';

export const NoArticlesFound = () => {
  return (
    <div className="mkt-w-full mkt-bg-white mkt-h-[480px] mkt-shadow-md mkt-rounded-md mkt-py-5 mkt-my-6 mkt-flex mkt-flex-col mkt-items-center mkt-gap-4 mkt-z-50">
      <img
        src={noArticlesFoundImg}
        className="mkt-w-[300px] mkt-h-[300px]"
        alt="not found articles"
      />
      <div className="mkt-text-center">
        <h1 className="mkt-text-blue-dark mkt-font-sans mkt-text-4xl mkt-font-bold">
          No articles found
        </h1>
      </div>
    </div>
  );
};
