import { Builder } from '@builder.io/react';
import { BuilderComponents, BuilderModels } from '../../helpers/constants';
import { BlogArticleSubtitle } from './BlogArticleSubtitle';

Builder.registerComponent(BlogArticleSubtitle, {
  name: BuilderComponents.BLOG_ARTICLE_SUBTITLE,
  models: [BuilderModels.BLOG_ARTICLE, BuilderModels.PRESS_ARTICLE],
  inputs: [
    {
      friendlyName: 'Text',
      name: 'text',
      type: 'text',
      defaultValue: 'Example text...',
      required: true,
    },
  ],
});
