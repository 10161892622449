/* eslint-disable react-hooks/exhaustive-deps */
import { PressHeader } from './PressHeader/PressHeader';
import { PageProps } from '../../global/types/page.types';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { PressIndex } from './PressSection/PressIndex';
import { useEffect } from 'react';
import { usePressStore } from '../../store/press/press.store';

export const Press: React.FC<PageProps> = ({ pageTitle }) => {
  useDocumentTitle(pageTitle);

  const {
    allPressArticles,
    getAllPressArticles,
    isAllPressArticlesLoading,
    allExternalPressArticles,
    getAllExternalPressArticles,
    isAllExternalPressArticlesLoading,
  } = usePressStore();

  useEffect(() => {
    Promise.all([getAllExternalPressArticles(), getAllPressArticles()]);
  }, []);

  return (
    <div>
      <PressHeader />
      <PressIndex
        allPressArticles={allPressArticles}
        isLoading={
          isAllPressArticlesLoading || isAllExternalPressArticlesLoading
        }
        allExternalPressArticles={allExternalPressArticles}
      />
    </div>
  );
};
