import { useEffect, useState } from 'react';
import { RedirectUrlModel } from '../data/builderio/url-redirects/redirect-url-model';
import { getUrlRedirects } from '../data/builderio/url-redirects/get-redirects';

/**
 *
 * @returns redirect pages set on builder, we only need to check this on first render
 */
export const useRedirectUrl = () => {
  const [redirectUrl, setRedirectUrl] = useState<
    undefined | RedirectUrlModel[]
  >(undefined);

  useEffect(() => {
    getUrlRedirects().then(res => setRedirectUrl(res as RedirectUrlModel[]));
  }, []);

  return redirectUrl;
};
