import { Builder } from '@builder.io/react';
import { Component } from '@builder.io/sdk';
import { MktFaqsAccordion } from '@securitize/reactjs-mkt-ui';
import { BuilderComponents } from '../../helpers/constants';

const FaqsAccordionBuilderConfig: Component = {
  name: BuilderComponents.FAQS_ACCORDION,

  inputs: [
    {
      name: 'tabs',
      friendlyName: 'Tabs',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'title',
          friendlyName: 'Title',
          type: 'string',
          required: true,
        },
        {
          name: 'description',
          friendlyName: 'Description',
          type: 'richText',
          required: true,
        },
      ],
    },
  ],
};

Builder.registerComponent(MktFaqsAccordion, FaqsAccordionBuilderConfig);
