import { Builder, withChildren } from '@builder.io/react';
import { MktCarrousel } from '@securitize/reactjs-mkt-ui';
import React from 'react';

//@ts-ignore
export const Carrousel = ({ children, variant }) => {
  //@ts-ignore
  const elements = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child);
    }
    return null;
  });
  return <MktCarrousel variant={variant} elements={elements}></MktCarrousel>;
};
// pass your custom component to withChildren()
const HeroWithBuilderChildren = withChildren(Carrousel);

// specify defaultChildren when you register the component
Builder.registerComponent(HeroWithBuilderChildren, {
  name: 'Carrousel',
  // Adding defaults is important for easy usability
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'I am child text block!' } },
    },
  ],
  inputs: [
    {
      name: 'variant',
      friendlyName: 'Variant Color',
      type: 'string',
      defaultValue: 'light',
      enum: ['light', 'dark'],
      required: true,
    },
  ],
});
