import { Builder } from '@builder.io/react';
import { Component } from '@builder.io/sdk';
import { MktTestimonialsSlider } from '@securitize/reactjs-mkt-ui';
import { BuilderComponents } from '../../helpers/constants';

const TestimonialsSliderBuilderConfig: Component = {
  name: BuilderComponents.TESTIMONIALS_SLIDER,
  image:
    'https://cdn.builder.io/api/v1/image/assets%2Fd39b51a544e84e2fbb2445f58c6c6f2c%2F7214d2d7a3ed48e2acbe65d5b40b29be?width=500&height=500',
  inputs: [
    {
      name: 'testimonials',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'position',
          type: 'string',
          required: true,
        },
        {
          name: 'testimony',
          type: 'string',
          required: true,
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png'],
        },
      ],
    },
    {
      friendlyName: 'Background Color',
      name: 'bgColor',
      type: 'string',
      defaultValue: 'default',
      enum: ['default', 'darker', 'softer'],
      required: false,
      advanced: true,
    },
    {
      friendlyName: 'Time between slides',
      name: 'autoplaySpeedTransition',
      type: 'number',
      defaultValue: 5000,
      required: false,
      advanced: true,
    },
  ],
};

Builder.registerComponent(
  MktTestimonialsSlider,
  TestimonialsSliderBuilderConfig,
);
